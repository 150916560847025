;(function() {
/* @ngInject */
function ZalozenieSroKrok5Controller(ZalozenieSroKrok5Service, UserService, $window, Helpers, ModalFinishLater, ModalInfo, $scope, ModalTatraBanka, $location) {
  let vm = this;

  let server = ZalozenieSroKrok5Service;
  vm.server = server;

  let userService = UserService;

  vm.selected = [];
  vm.orderId = 0;
  vm.isSinglePerson = false;
  vm.upsells = {};
  vm.isTbCampaign = false;
  vm.showModalTB = false;

  vm.user = {};

  vm.formatName = formatName;
  vm.formatDate = Helpers.formatDate;
  vm.onFinishClick = onFinishClick;
  vm.onSaveForLaterClick = onSaveForLaterClick;
  vm.onBackClick = onBackClick;
  vm.scrollTo = Helpers.scrollTo;
  vm.dateToString = Helpers.dateToString;
  vm.idNumberToString = Helpers.idNumberToString;
  vm.mergeStreetNo = Helpers.mergeStreetNo;

  window.showSpinner(11);
  server.getCompanyName().then(onCompanyNameLoad);
  server.getPlaceOfBusiness().then(onPlaceOfBusinessLoad);
  server.getFoundersManagers().then(onFoundersManagersLoad);
  server.getSelectedSubjects().then(onSelectedSubjectsLoad);
  server.getOtherInfo().then(onOtherInfoLoad);
  server.getCart().then(onCartLoad);
  server.getOrderId().then((id) => { vm.orderId = id; window.hideSpinnerWhenAllResolved(); });
  server.getUpsell().then((upsell) => { vm.upsells = upsell; window.hideSpinnerWhenAllResolved(); });
  server.getSinglePerson().then((isSingle) => { vm.isSinglePerson = isSingle; if(isSingle) showInfoOneDoc(); window.hideSpinnerWhenAllResolved(); });
  server.getTBCampaign().then((isTbCampaign) => { vm.isTbCampaign = isTbCampaign; window.hideSpinnerWhenAllResolved() });
  server.showLastStepModalTB().then((res) => vm.showModalTB = res);

  userService.getLoggedUser().then((user) => {vm.user = user; window.hideSpinnerWhenAllResolved()});

  $scope.$on('on-price-change', function(event, args) {
    server.getCart().then(onCartLoad);
  });

  vm.maxStep = 5;
  vm.cart = { };

  vm.agreements = {
    agrees: false,
    representation: true
  };

  vm.inProgress = false;
  vm.requiredCheckbox = false;

  function showTatraBankaModal() {
    ModalTatraBanka('modalTatraBanka.lastStep.sro.title', 'modalTatraBanka.lastStep.sro.text', 86)
        .add()
        .result
        .then(res => {
          server.setTBDiscount((res && res === "confirm").toString()).then(() => {
            if(res && res === "confirm") {
              server.saveLastStepTBUpsell($location.absUrl()).then();
            }
            vm.showModalTB = false;
            onFinishClick();
          });
        });
  }

  function showInfoOneDoc() {
    ModalInfo("sro.zalozenie.krok5.infoOneDoc.title", "sro.zalozenie.krok5.infoOneDoc.desc", "buttons.understand")
      .add()
      .result
      .then();
  }

  function onSaveForLaterClick() {
    ModalFinishLater(server)
      .add(server)
      .result
      .then();
  }

  function formatName(name) {
    if(name) {
      return name.name + name.suffix;
    }
  }

  function onCartLoad(c) {
    vm.cart.price = c.totalPrice;
    vm.cart.placeOfWork = c.placeOfBusinessPrice;
    vm.cart.size = c.subjectCount;

    window.hideSpinnerWhenAllResolved();
  }

  function onCompanyNameLoad(cn) {
    if (angular.isDefined(cn)) {
      vm.companyName = cn;
    }

    window.hideSpinnerWhenAllResolved();
  }

  function onPlaceOfBusinessLoad(pb) {
    if (angular.isDefined(pb)) {
      vm.placeOfBusiness = pb;
    }

    window.hideSpinnerWhenAllResolved();
  }

  function onFoundersManagersLoad(order) {
    if(order) {
      vm.order = order;
      //console.log(vm.order);
      vm.founders = order.founders.concat(order.foundersCompanies);
      vm.executiveManagers = order.executiveManagers;
      vm.executiveFounders = [];
      vm.founders.forEach(addExecutiveFounder);
    }
    console.log(vm.founders);

    window.hideSpinnerWhenAllResolved();
  }

  function addExecutiveFounder(f) {
    if (f.isExecutiveManager) {
      let e = {};
      e.address = f.address;
      e.dateOfBirth = f.dateOfBirth;
      e.idNumber = f.idNumber;
      e.name = f.name;
      e.surname = f.surname;
      e.otherInfo = f.otherInfo;
      e.titleBefore = f.titleBefore;
      e.titleAfter = f.titleAfter;
      vm.executiveFounders.push(e);
      vm.executiveManagers.unshift(e);
    }
  }

  function onSelectedSubjectsLoad(ss) {
    if (angular.isDefined(ss)) {
      vm.selected = ss.subjects;
      vm.cart.size = ss.subjects.length;
      //console.log(vm.selected);
    }

    window.hideSpinnerWhenAllResolved();
  }


  function onOtherInfoLoad(order) {
    if(order) {
      vm.otherInfo = order;
    }

    window.hideSpinnerWhenAllResolved();
  }

  function onFinishClick() {
    if(!vm.agreements.agrees) {
      vm.requiredCheckbox = true;
      vm.scrollTo("scroll_checkbox");
    } else {
      if(vm.showModalTB) {
        showTatraBankaModal();
      } else {
        var dataLayer = Helpers.getDataLayer(vm.orderId,
            vm.isSinglePerson ? '1osobova_sro' : 'zalozenie_sro',
            'sro', '', vm.user);
        $window.localStorage.setItem('dataLayer', JSON.stringify(dataLayer));
        vm.inProgress = true;
        window.showSpinner(1);
        // todo: data + validation
        server.finish({
          agrees: vm.agreements.agrees,
          requestsRepresentation: vm.agreements.representation
        }).then(({success, data, error}) => {
          if (success) {
            $window.location = "objednane";
          }
          vm.inProgress = false;
          window.hideSpinnerWhenAllResolved();
        })
            .catch(error => {
              console.log(error);
              vm.inProgress = false;
              window.hideSpinnerWhenAllResolved();
            });
      }
    }
  }

  function onBackClick() {
    $window.location = "krok4";
  }
}

angular.module("firmaren")
  .controller("ZalozenieSroKrok5Controller", ZalozenieSroKrok5Controller);
}());
